body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.slick-prev:before, .slick-next:before {
color: black;
}




.mobile-demo{
  background-image: url(/static/media/mob.3ed41c84.png);
  width: 530px;
  height: 668px;
  margin: auto;
  margin-bottom:100px;
  background-size:cover;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  position: absolute;
  top:-23px;

}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

body{
  margin: 0;
  padding: 0;
  overflow-x:hidden !important;
  
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'mediumCondensed';
  src: url('/fonts/HelveticaNeue-MediumCond.otf') format('opentype');
}

@font-face {
  font-family: 'helveticaRoman';
  src: url('/fonts/HelveticaNeue-Roman.otf') format('opentype');
}

@font-face {
  font-family: 'mostraNuova';
  src: url('/fonts/mostra_nuova_light.otf') format('opentype');
}

.section-one-container{
  /* background: white; */
}

.section-one {
  background: #fba922;
  height: 500px;
 
  /* transform:rotate(7deg);
  width:110%;
  top:-5%;
  left:-5%; */
  -webkit-clip-path: polygon(
    0 0, /* left top */
    100% 0, /* right top */ 
    100% 100%, /* right bottom */
    0 80% /* left bottom */
  );
          clip-path: polygon(
    0 0, /* left top */
    100% 0, /* right top */ 
    100% 100%, /* right bottom */
    0 80% /* left bottom */
  );
  /* padding: 60px; */
  

}

/* .section-one::after {
  position:absolute;
  content: "";
  display:block;
  transform:rotate(7deg);
  left:0;
  bottom:-20px;
  width:100%;
  z-index: 1000;
  border-style: solid;
  border-width: 0 100vw 20px 0;
  border-color: #fba922 rgba(0,0,0,0.4) #fba922 #fba922;
} */


@media only screen and (max-width: 800px) {
  .section-one {
    padding: 10px;
    margin-bottom: 150px;
    
   
  }
}

@media screen and (min-width: 768px) {
 .navbar-toggle {
     display: none;
  }
}

.navbar {
  background-color: #fba922 !important;
  margin: 0;
  padding:15px;
  display: flex;
  /* justify-content: space-between; */
  padding-bottom: 0;
  height: 70px;
  align-items: center;
  /* padding: 11px !important; */
  /* box-sizing: border-box; */
  /* height: 100px !important; */
  /* display: none !important; */
  /* height: fit-content !important; */

}

@media only screen and (max-width: 800px){
  .navbar{
    padding:5px;
    height: 30px;
  }
}

/* .navbar-logo {
  font-family: 'mostraNuova';
  font-size: 32px;
  color: white;
  background: #70142c;
  padding: 35px 5px;
  position: relative;
  left: 30px;
} */


.navbar-toggle {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer; 
    color: rgba(255,255,255,0.8);
    font-size: 28px;
    height:20px;
    width: auto;
  /* display: none; */
}

.navbar-logo {
  font-family: 'mostraNuova';
  font-size: 38px;
  text-decoration: none;
  color:#70142c;
  /* padding: 35px 5px; */
  /* position: relative; */
  /* top: 35px; */
  /* left: 30px; */
  margin-right: 10%;
  margin-left: 2%;
  display: inline-block;
  
}

@media only screen and (max-width: 800px) {
  .navbar-logo{
    position: absolute;
    font-size: 26px;
    left: 0;
    top: 15px;
  }
}

.navbar-list-items{
  list-style-type: none;
  font-family: 'helveticaRoman';
  margin: 0;
  font-size: 14px;
  margin-right: 30px;
  display: flex;
  margin-right: 30px;
  flex-direction: row;
  justify-content: flex-end;

}

@media only screen and (max-width: 800px){
  .navbar-list-items{
    display: none;
  }
}

.navbar-list-items li {
  text-align: center;
}

.navbar-item{
  text-decoration: none;
  color: #70142c;
  font-size: 16px;
  margin-right:100px;
}

.header-text {
  position: relative;
  font-family: 'mediumCondensed';
  color: white;
  font-size: 78px !important;
  font-weight: 100 !important;
  max-width: 620px;
  top: 52px;
  left: 120px;
}

@media only screen and (max-width: 800px) {
  .header-text {
    font-size: 37px !important;
    position: static !important;
    /* margin-top: 90px; */
  }
}



.pulse-image {
  position: relative;
  /* visibility: hidden; */
  left: 280px;
  top: 60px;
}

@media only screen and (max-width: 800px) {
  .pulse-image {
    position: static;
    max-width: 100%;
    /* visibility: hidden; */
    top:30px;
    height: auto;
  }
}

.section-two {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  /* box-sizing: border-box; */
}

.feature-icon {
  height: 50px;
  width: auto;
  margin: 10px;
}

.feature-title {
  font-family: 'mediumCondensed';
  color: #70142c;
  font-size: 20px;
}

.feature-description {
  /* margin-top: -1px;  */
  max-width: 247px; /*276px*/
  margin-left: auto;
  margin-right: auto;
  font-family: 'helveticaRoman';
  color: #959595;
  font-size: 14px;
}

.restaurants-section {
  background: #f2f2f2;
  padding: 50px;
  text-align: center;
  align-items: center;
}

.logos {
  /* display: inline-block; */
  padding: 24px;
  /* text-align: center;
  align-items: center; */
}

/* .restaurant-logo-container{
  text-align: center;
  position: relative;
} */

.restaurant-logo-container{
  display: inline-block;
}

.restaurant-logo {
  height: 110px;
  width: auto;
  /* display: inline-block; */
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  margin: auto;
}

.section-three {
  background: #70142c;
  padding: 70px;
  height: 500px;
  width: auto;
  overflow-y: hidden;
  /* display: inline-block; */
}

@media only screen and (max-width: 800px) {
  .section-three {
    padding: 20px;
    height: 600px;
  }
}

.phone-wrapper{
  background: url("/phone.png") center center no-repeat;
  position: relative;
  /* background-size: contain; */
    /* padding-top: 25px;
    padding-bottom: 67.5%; */
     height: auto;
     width:100%;
     max-width: 100%;
     
     /* padding:35% 0% 10% 4%; */
}

.phone-image{
  background: url("/phone.png") no-repeat top left transparent;
  width: 100%;
  height: auto;
  position: relative;
}

@media only screen and (max-width: 800px){
  .phone-image{
    max-width: 100%;
    height: auto;
  }
}

.video{
  position: absolute;
  top: 30px; /* Adjust top position */
  left: 40px;
}

.phone-img{

  /* height: 800px;
  width: auto; */
  max-width: 100%;
  height: auto;
}

.phone-text {
  position: relative;
  font-family: 'mediumCondensed';
  color: white;
  font-size: 100px;
  max-width: 500px;
  top: 150px;
}

@media only screen and (max-width: 800px) {
  .phone-text {
    /* position: relative;
    left:50%;
    top:25%; */
    /* display:inline-block; */
    font-size: 70px;
    position: static;
    /* margin-right: auto;
    margin-left: auto;
    max-width: 100%; */
  }
}

.section-four {
  padding: 80px;
}

@media only screen and (max-width: 800px) {
  .section-four {
    padding: 20px;
  }
}

.desktop-img {
  width: 600px;
  height: auto;
}

@media only screen and (max-width: 800px) {
  .desktop-img {
    max-width: 100%;
    height: auto;
  }
}

.desktop-text {
  position: relative;
  font-family: 'mediumCondensed';
  color: #70142c;
  font-size: 100px;
  max-width: 500px;
  top: 90px;
  left: 120px;
}

@media only screen and (max-width: 800px) {
  .desktop-text {
    font-size: 70px;
    position: static;
  }
}

.team-section {
  /* background: #fba922; */
  background: #FEFEFC;
  /* padding: 60px; */
  padding: 20px;
}


@media only screen and (max-width: 800px) {
  .team-section {
   padding: 1px;
  }
}


.title {
  font-family: 'mediumCondensed';
  font-size: 64px;
  color: #70142c;
  /* max-width: 339px;
  margin-left: auto;
  margin-right: auto; */
  margin-bottom: 30px;
  margin-top:20px;
  text-align: center;
}

.team-title{
  margin:0;
}

@media only screen and (max-width: 800px) {
  .title {
    /* position: relative;
    top: 40px;
    bottom: 30px; */
  }
  
}

/* Slider Css */

.slider-title {
  font-family: 'mediumCondensed';
  font-size: 64px;
  color: #70142c;
  margin-left: auto;
  margin-right: auto;
}

.slider-header {
  font-family: 'helveticaRoman';
  font-size: 20px;
  text-align: left;
}

.data-counter-section{
  padding:30px;
  overflow-x:hidden;
  /* background: #4C4C4C; */
  /* background: #0B2239; */
  background: #fba922;
  font-size: 20px;
  color: #FEFEFC;
  font-family: 'mostraNuova';
  text-align: center;
}

.data-counter-Hdivider{
  color: #FEFEFC !important;
}

.data-counter-Vdivider{
  /* border-right: 0.1px solid #b3b5b7; */
  /* border-right: 0.1px solid #FEFEFC; */
}

@media only screen and (max-width: 800px) {
  .data-counter-Vdivider{
    border-right: 0 solid;
    /* border-bottom: 0.1px solid; */
    
  }
}

.messages-counter{
  display: inline-block;
  font-size: 40px;
 
  /* font-weight: 600; */
  
}

@media only screen and (max-width: 800px) {
  .data-counter-section{
    padding: 30px;
    
  }
}

.users-counter-icon{
  width: 70px;
  height: auto;
  margin-bottom: 20px;
}

.users-counter{
  display: inline-block;
  font-size: 40px;
  /* font-weight: 600; */
}

.elegate {
  font-family: 'mostraNuova';
  color: #70142c;
  font-size: 32px;
  font-weight: bold;
}

.success {
  font-weight: bold;
}

.danger {
  font-weight: bold;
}

/* End of Slider Css */

@media only screen and (max-width: 800px) {
  .title {
    font-size: 55px;
  }
}

.team-members-one {
  /* padding-right: 100px;
  padding-left: 100px; */
  max-width: 902px;
  margin-right: auto;
  margin-left:auto;
  margin-bottom:25px;
}

@media only screen and (max-width: 800px) {
  .team-members-one {
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
}

.member-container {
  max-width: 270px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  /* padding: 20px; */
}

/* .photo-container{
 height: 80px;
 width: auto; 
} */

.member-photo {
  height: 170px;
  width: 170px;

  border-radius: 50%;
  border: 8px solid white;
  object-fit: cover;
}

.member-name {
  font-family: 'mediumCondensed';
  font-size: 23px;
}

.member-role {
  font-family: 'mostraNuova';
  font-size: 18px;
}

.contact-section {
  /* background: #F2F2F2; */

  /* padding: 40px; */

  background: #f2f2f2;
}

.contact-us-container {
  background: white;
  margin-top: 20px;
  margin-bottom: 10px;
  /* margin-left: 40px; */
  padding: 20px;
  text-align: left;
  font-family: 'helveticaRoman';
}

@media only screen and (max-width: 800px) {
  .contact-us-container {
margin-left: 40px;
margin-right: 40px;
  }
}

.elegate-logo {
  height: 110px;
  width: auto;
  margin: 24px;
}

.contact-row {
  margin-top: 20px;
}

.icon {
  vertical-align: middle;
}

.follow-us-container {
  background: #222;
  margin-top: 20px;
  margin-bottom: 10px;
  /* margin-left: 40px; */
  padding: 25px;
  padding-top: 10px;
  text-align: center;
  color: #fba922;
  /* border-top-right-radius: 4px; */
  border-top-left-radius: 4px;
  font-family: 'helveticaRoman';
}

@media only screen and (max-width: 800px) {
  .follow-us-container {
margin-left: 40px;
margin-right: 40px;
  }
}

.social-buttons {
  margin: 5px;
}

.btn {
  background: #fba922 !important;

  /* width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    vertical-align: middle;
    padding: 10px; */
  /* color: #222 !important;
    background-color: red; */
}

.btn:hover {
  background: #70142c !important;
}

.social-icon {
  height: 20px;
  width: auto;
}

.address-container {
  background: white;
  /* width: 100%;
  height: 100%; */
  margin: 20px;
  margin-right: 0px;
  padding: 98px;
  max-height: 130px;
  font-family: 'helveticaRoman';
}

/* .footer{
  background: #222;
  height: 50px !important;
  width: 100%;
} */

.pulse{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
/*   margin:100px; */
/*   display: block; */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #cca92c;
  cursor: pointer;
/*   box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
   animation: animate 3s linear infinite;  */
  
}
.pulse2{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
/*   margin:100px; */
/*   display: block; */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  z-index:-1;
  -webkit-animation: animate 3s linear infinite;
}
.pulse3{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
/*   margin:100px; */
/*   display: block; */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  z-index:-1;
  -webkit-animation: animate2 3s linear infinite;
}
.pulse4{
  position:absolute;
  top:50%;
  left:50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
/*   margin:100px; */
/*   display: block; */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: red;
  z-index:-1;
  -webkit-animation: animate3 3s linear infinite;
}
/* .pulse:after{
  animation-delay: 0.5s;
} */
.pulse:hover {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes animate{
  from{
   -webkit-transform:translate(-50%,-50%)scale(0.5);
           transform:translate(-50%,-50%)scale(0.5);
  opacity:0.5;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(3);
         transform: translate(-50%,-50%)scale(3);
  opacity:0.2;
  }
}

@keyframes animate{
  from{
   -webkit-transform:translate(-50%,-50%)scale(0.5);
           transform:translate(-50%,-50%)scale(0.5);
  opacity:0.5;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(3);
         transform: translate(-50%,-50%)scale(3);
  opacity:0.2;
  }
}
@-webkit-keyframes animate2{
  from{
   -webkit-transform:translate(-50%,-50%)scale(0);
           transform:translate(-50%,-50%)scale(0);
  opacity:0.4;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(2);
         transform: translate(-50%,-50%)scale(2);
  opacity:0.1;
  }
}
@keyframes animate2{
  from{
   -webkit-transform:translate(-50%,-50%)scale(0);
           transform:translate(-50%,-50%)scale(0);
  opacity:0.4;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(2);
         transform: translate(-50%,-50%)scale(2);
  opacity:0.1;
  }
}
@-webkit-keyframes animate3{
  from{
   -webkit-transform:translate(-50%,-50%)scale(1);
           transform:translate(-50%,-50%)scale(1);
  opacity:0.3;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(5);
         transform: translate(-50%,-50%)scale(5);
  opacity:0;
  }
}
@keyframes animate3{
  from{
   -webkit-transform:translate(-50%,-50%)scale(1);
           transform:translate(-50%,-50%)scale(1);
  opacity:0.3;
  }
  to{
 -webkit-transform: translate(-50%,-50%)scale(5);
         transform: translate(-50%,-50%)scale(5);
  opacity:0;
  }
}

div.laptop-wrapper {
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;
}
div.laptop-wrapper iframe {
  /* box-sizing: border-box; */
  background: url("/phone.png") center center no-repeat;
  background-size: contain;
  padding: 11.9% 15.5% 14.8%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.slider {
  height: 4px;
  /* position: absolute; */
  position: relative;
}

.rangeslider {
  box-shadow: inset 0px 5px 3px rgba(0, 0, 0, 0.1);
}

.slider .rangeslider__handle {
  width: 15px;
  height: 15px;
  left: -13px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 1px #333;
}

.rangeslider__fill {
  background-color: #70142c !important;
}

.rangeslider-horizontal .rangeslider__handle {
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  /* content: ' ';
  position: absolute;
    width: 16px;
    height: 16px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: #dadada;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset; */
  display: none;
}

.value-container {
  text-align: center;
  margin: 10px 10px 20px;
}

.value {
  /* background: #F2F2F2; */
  /* width: fit-content; */
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
  display: inline-block;
  /* padding: 6px; */
  border-radius: 3px;
  color: #26e614;
  font-family: 'mediumCondensed';
  font-size: 18px;
}

.value-unit {
  display: inline-block;
  margin-left: 5px;
  height: 20px;
  vertical-align: middle;
}

.form-paper {
  /* position: relative; */
  position: absolute;
  z-index: 1000;
  /* width: fit-content; */
  width: 30%;
  top: 60px;
  left: 62%;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .form-paper {
    width: 91%;
    top: 33%;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 90px;
  }
}

.form-container {
  /* padding: 10px; */
}

.form-title {
  font-family: "MediumCondensed";
  margin-top: 8%;
  font-size: 18px;
}

.form-feilds-container {
  margin: 25px 15px 15px;
}

.form-feilds {
  background: rgba(242, 242, 242, 0.618);
  border-radius: 4px;
  margin-bottom: 7px !important;
  font-size: 9px !important;
  /* padding-left: 6px !important; */
}

.form-delivery {
  text-align: left;
  font-size: 13px;
  margin-top: 6%;
  font-family: "helveticaRoman";
}

.form-ordersPweek {
  text-align: left;
  font-size: 13px;
  margin-top: 2%;
  font-family: "helveticaRoman";
}

.form-button {
  width: 100%;
  height: 48px;
  /* background-color: #70142c !important; */
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 20px;
}
.successMsg {
  text-align: center;
  color: green;
}

