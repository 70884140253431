.form-paper {
  /* position: relative; */
  position: absolute;
  z-index: 1000;
  /* width: fit-content; */
  width: 30%;
  top: 60px;
  left: 62%;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .form-paper {
    width: 91%;
    top: 33%;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 90px;
  }
}

.form-container {
  /* padding: 10px; */
}

.form-title {
  font-family: "MediumCondensed";
  margin-top: 8%;
  font-size: 18px;
}

.form-feilds-container {
  margin: 25px 15px 15px;
}

.form-feilds {
  background: rgba(242, 242, 242, 0.618);
  border-radius: 4px;
  margin-bottom: 7px !important;
  font-size: 9px !important;
  /* padding-left: 6px !important; */
}

.form-delivery {
  text-align: left;
  font-size: 13px;
  margin-top: 6%;
  font-family: "helveticaRoman";
}

.form-ordersPweek {
  text-align: left;
  font-size: 13px;
  margin-top: 2%;
  font-family: "helveticaRoman";
}

.form-button {
  width: 100%;
  height: 48px;
  /* background-color: #70142c !important; */
  color: white !important;
  text-transform: capitalize !important;
  margin-bottom: 20px;
}
.successMsg {
  text-align: center;
  color: green;
}
