.slider {
  height: 4px;
  /* position: absolute; */
  position: relative;
}

.rangeslider {
  box-shadow: inset 0px 5px 3px rgba(0, 0, 0, 0.1);
}

.slider .rangeslider__handle {
  width: 15px;
  height: 15px;
  left: -13px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 1px #333;
}

.rangeslider__fill {
  background-color: #70142c !important;
}

.rangeslider-horizontal .rangeslider__handle {
  outline: none;
}

.rangeslider-horizontal .rangeslider__handle:after {
  /* content: ' ';
  position: absolute;
    width: 16px;
    height: 16px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: #dadada;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset; */
  display: none;
}

.value-container {
  text-align: center;
  margin: 10px 10px 20px;
}

.value {
  /* background: #F2F2F2; */
  /* width: fit-content; */
  width: max-content;
  text-align: center;
  display: inline-block;
  /* padding: 6px; */
  border-radius: 3px;
  color: #26e614;
  font-family: 'mediumCondensed';
  font-size: 18px;
}

.value-unit {
  display: inline-block;
  margin-left: 5px;
  height: 20px;
  vertical-align: middle;
}
